@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: linear-gradient(to bottom right, #4b0082, #000000, #000080); /* Adjusted colors to match the Tailwind gradient */
  color: #d1d5db; /* Equivalent to Tailwind's text-gray-200 */
  min-height: 100vh;
}

.text-outline-black {
  position: relative;
  color: transparent;
  -webkit-text-stroke: 1px black; /* Siyah dış hat ekler */
}

.text-outline-black::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  color: black;
  -webkit-text-stroke: 0;
  visibility: visible;
}

@keyframes bgMove {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animate-bgMove {
  animation: bgMove 10s linear infinite;
}

.animate-gradient {
  animation: gradientAnimation 6s ease infinite;
}

@keyframes gradientAnimation {
  0% { stroke-dasharray: 1, 150; stroke-dashoffset: 0; }
  50% { stroke-dasharray: 90, 150; stroke-dashoffset: -35; }
  100% { stroke-dasharray: 90, 150; stroke-dashoffset: -124; }
}

/* index.css */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  border: 12px solid rgb(255, 255, 255);
  border-top: 1px solid #00000000; /* White color */
  border-radius: 50%;
  width: 150px; /* Adjust size */
  height: 150px; /* Adjust size */
  animation: spin 4s linear infinite;
  position: relative;
}

.spinner-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px; /* Same as spinner size */
  height: 150px; /* Same as spinner size */
}

.spinner-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 15px; /* Adjust font size */
  font-weight: bold; /* Make text bold */
  pointer-events: none; /* Ensure text doesn't block interactions */
  text-align: center;
  white-space: nowrap; /* Prevent text from wrapping */
}

